<template>
  <div>
    <el-form :model="form" ref="form" :hide-required-asterisk="true">
          <el-form-item
            :label="$t('Office')"
            prop="office"
            :rules="[{ required: true, message: 'The field is required'}]"
          >
            <el-select
              v-model="form.office"
              placeholder="Select Office"
              class="w-100"
              @change="getClients"
            >
              <el-option
                v-for="item in offices"
                :key="item.id"
                :label="item.office_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            :label="$t('Client')"
            prop="client"
          >
            <el-select
              v-model="form.client"
              placeholder="Select Client"
              class="w-100"
            >
              <el-option
                v-for="item in clients"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item> 

          <el-form-item
            :label="$t('Note Subject')"
            prop="title"
            :rules="[{ required: true, message: 'The field is required'}]"
          >
            <el-input type="text" v-model="form.title"></el-input>
          </el-form-item>

          <el-form-item
            :label="$t('Note Detail')"
            prop="detail"
            :rules="[{ required: true, message: 'The field is required'}]"
          >
            <el-input type="textarea" v-model="form.detail" :rows="5"></el-input>
          </el-form-item>                 

      <el-button type="success" @click="(!data)?submitForm('form'):updateForm('form')">Save Note</el-button>
    </el-form>
  </div>
</template>

<script>
import corporate from "@/services/api/corporate";
import office from "@/services/api/office";
import note from "@/services/api/note";

export default {
  props: ["data"],
  data() {
    return {
      title: '',
      due_date: '',
      detail: '',
      clients: [],
      offices: [],
      categories: [],
      form: {
        title: null,
        detail: null,
        client: null,
        office: null,
        asignto: null,
        duedate: null,
        user: this.$store.getters.id
      }
    };
  },
  mounted() {
    office.get().then(response => {
      this.offices = response;
    });
    //
    if (this.data) {
      this.form.id = this.data.id;
      this.form.title = this.data.title;
      this.form.detail = this.data.detail;
      this.form.duedate = this.data.duedate;
      this.form.office = this.data.officeId;
      corporate.getClientsByOffice(this.form.office).then(response => {
        this.clients = response;
      });
      this.form.client = this.data.clientId;
      this.form.user = this.data.userId;
    }
  },
  methods: {

    getClients(){
      this.form.client=null;
      corporate.getClientsByOffice(this.form.office).then(response => {
        this.clients = response;
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          note
            .create(this.form)
            .then(response => {
              this.$emit("refresh", { data: response, update: false });
              this.$message({
                message: "Note registred...",
                type: "success",
                customClass: "message-success"
              });
            })
            .catch(() => {
              this.$message({
                message: this.$store.getters.message.message,
                type: "error",
                customClass: "message-error"
              });
            });
        } else {
          return false;
        }
      });
    },
    updateForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          note
            .update(this.form)
            .then(response => {
              this.$emit("refresh", { data: response, update: true });
            })
            .catch(() => {
              this.$message({
                message: this.$store.getters.message.message,
                type: "error",
                customClass: "message-error"
              });
            });
        } else {
          return false;
        }
      });
    }
  }
};
</script>
